











































































































































// Core
import {Component, Prop, PropSync, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'

// Types
import {ProjectsActions} from '@/store/projects/types'

// Interfaces
import {ElForm} from 'element-ui/types/form'
import {ErgzProject} from "@/api/interfaces";
import {Project} from '@/store/projects/interfaces'
import Debounce from "@/decorators/Debounce";
import {getData} from "@/api/egrz";
import {projectNamespace} from "@store/project";
import {ProjectActionTypes} from "@store/project/Types";

// Modules
const NSProjects = namespace('projectsModule')

@Component({
  name: 'DialogProjectInfo',
})
export default class DialogProjectInfoComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop({
    type: Object,
    default: () => ({}),
  })
  private project!: Project

  @NSProjects.Action(ProjectsActions.A_UPDATE_PROJECT)
  private updateProject!: (project: Project) => Promise<Project>

  @projectNamespace.Action(ProjectActionTypes.A_CREATE_OR_UPDATE_LEGAL)
  private createOrUpdateLegalPerson!: (person) => Promise<void>

  private isExpectation: boolean = false

  private form: Project = {...this.project}

  protected rules = {
    name: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    address: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    startDate: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    endDate: [
      {required: true, message: 'Обязательное поле', trigger: 'blur'},
      {validator: this.validateEndDate, trigger: 'blur'},
    ],
  }

  protected pickerOptions = {
    disabledDate: time => {
      return time.getTime() < new Date(this.form.startDate)
    },
  }

  private validateEndDate(_rule, value, callback) {
    if (isAfter(parseISO(value), parseISO(this.form.startDate))) {
      callback()
    } else {
      callback(new Error('Дата не должна быть раньше, "Начала работ"'))
    }
  }

  private handleUpdateProject() {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.updateProject(this.form)
          .then(() => {
            this.isExpectation = false
            this._visible = false
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })


            console.log(this.builder)
            console.log(this.customer)
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }

  //Застройщик
  private builder = {name: '', inn: '', role_id: 2}

  //Заказчик
  private customer = {name: '', inn: '', role_id: 5}

  private legalPersonLoading: boolean = false;

  private handleSelectProject(egrz_project: ErgzProject) {
    if (egrz_project.ExpertiseObjectAddress) {
      this.form.address = egrz_project.ExpertiseObjectAddress
    }

    if (egrz_project.ExpertiseConclusionDate) {
      this.form.startDate = egrz_project.ExpertiseConclusionDate
    }

    //Застройщик
    this.builder.inn = ''
    if (egrz_project.DeveloperOrganizationInfo) {
      this.checkBuilderAndCustomer(egrz_project.DeveloperOrganizationInfo, this.builder)
    }

    //Заказчик
    this.customer.inn = ''
    if (egrz_project.TechnicalCustomerOrganizationInfo) {
      this.checkBuilderAndCustomer(egrz_project.TechnicalCustomerOrganizationInfo, this.customer)
    }
  }

  private addLegalPerson(payload) {
    this.legalPersonLoading = true
    this.createOrUpdateLegalPerson({project_id: this.form.id, ...payload}).then(() => {
      payload.inn = ''
      this.$notify({title: 'Выполнено', message: 'Действие успешно выполнено', type: 'success'})
    }).catch(() => {
      this.$notify.error({title: 'Ошибка', message: 'Во время добавления произошла ошибка'})
    }).finally(() => {
      this.legalPersonLoading = false
    })
  }

  protected checkBuilderAndCustomer(name: string, object: { name: string; inn: string; role_id: number }) {
    let matchInn = name.match(/инн: ([\d+]{10,12})/i)
    if (matchInn && matchInn[1]) {
      let inn = matchInn[1]
      let bracketIndex = name.lastIndexOf('(')

      if (~bracketIndex) {
        name = name.slice(0, bracketIndex)
      }

      object.inn = inn
      object.name = name
    }
  }

  @Debounce(1000)
  querySearchAsync(queryString, cb) {
    getData(queryString).then((data: ErgzProject[]) => {
      cb(data)
    })
  }


}
