import axios from 'axios';
import {ErgzProject} from '@/api/interfaces';
import http from '@/http';

const BASE_URL = 'https://open-api.egrz.ru/api/'


export async function getData(search: string):Promise<ErgzProject[]> {
  try{
    const {data} = await axios.get(`${BASE_URL}PublicRegistrationBook/`, {
      params: {
        '$filter': `contains(tolower(ExpertiseObjectNameAndAddress),tolower('${search}'))`,
        '$top': '5',
        '$skip': '0'
      }
    })

    return data.value
  } catch (e) {
    http.post('logs/sentry', {
      'message' : 'Ergz Fail Request ' + e.message,
      'payload' : []
    })
    return Promise.reject(e)
  }
}
